import React from 'react';
import rat from './images/ratWHITE.png';  // Adjust path if needed
import './App.css';                      // Component-specific CSS

function App() {
  return (
    <div className="container">
      <img className="logo" src={rat} alt="Ratwerks Engineering Logo" />
    </div>
  );
}

export default App;
